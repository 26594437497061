import { render, staticRenderFns } from "./DownloadSection.vue?vue&type=template&id=2a62c480&scoped=true&"
var script = {}
import style0 from "./DownloadSection.vue?vue&type=style&index=0&id=2a62c480&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a62c480",
  null
  
)

export default component.exports