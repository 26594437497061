<template>
    <section class="pb-8" id="contact">
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <v-row justify="center">
              <v-col cols="12" sm="5">
                <h1 class="font-weight-light display-1">Contactanos</h1>
                <h4 class="font-weight-light mt-3">
                  Si tiene alguna pregunta sobre nuestros productos o servicios, 
                  no dude en ponerse en contacto con nosotros a través de nuestro número 
                  de teléfono de atención al cliente o correo electrónico. Nuestro equipo de expertos 
                  estará encantado de ayudarlo con cualquier consulta que pueda tener.
                </h4>
                
                <h3 class="font-weight-light mt-3">
                  Telefono: 4772735165
                </h3>
                <h3 class="font-weight-light">
                  Email: hcarlosgarcia@depiff.com.mx
                </h3>
              </v-col>
              <v-col cols="12" sm="7">
                <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                  <v-text-field
                      v-model="name"
                      :rules="nameRules"
                      label="Nombre"
                      required
                  ></v-text-field>
  
                  <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                  ></v-text-field>
  
                  <v-textarea
                      v-model="textArea"
                      :rules="textAreaRules"
                      label="Msg"
                      required
                  />
  
                  <v-btn
                      :disabled="!valid"
                      color="primary"
                      :dark="valid"
                      rounded
                      block
                      class="mt-3"
                      @click="submit"
                  >
                    Enviar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <div class="svg-border-waves text-white">
        <v-img src="~@/assets/img/borderWavesBlue.svg"/>
      </div>
      <v-snackbar
          v-model="snackbar.enabled"
          timeout="3000"
          right
          top
          :color="snackbar.color"
      >
        {{ snackbar.text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar.enabled = false"
          >
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </section>
  </template>
  
  <style scoped>
  #contact {
    background-color: #f4f7f5;
  }
  
  .svg-border-waves .v-image {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
    overflow: hidden;
  }
  
  </style>
  <script>
  import emailjs from 'emailjs-com';

//   const templateParams = {
//     from_name: 'Victor',
//     message: 'Hola Xime y Sam!',
//     to_name: 'Victor'
// };
  
  export default {
    data: () => ({
      icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
      valid: true,
      name: "",
      nameRules: [
        (v) => !!v || "O campo nome é obrigatório",
        (v) => (v && v.length >= 6) || "O nome precisa ter mais de 6 caracteres",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "O campo email é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "O E-mail precisa ser válido",
      ],
      textArea: "",
      textAreaRules: [
        (v) => !!v || "O campo de texto é obrigatório",
        (v) => (v && v.length >= 10) || "Mínimo de 10 caracteres",
      ],
      lazy: false,
      snackbar: {
        enabled: false,
        text: '',
        color: ''
      }
    }),
    methods: {

  submit() {
    let templateParams = {
    from_name: this.name,
    message: this.textArea,
    to_name: 'Depiff Seguros',
    email: this.email
};
 
  emailjs.send(process.env.VUE_APP_ID_USER_MAIL,'template_btginip', templateParams,process.env.VUE_APP_EMAIL_KEY)

  
  .then(() => { 
    this.snackbar.text = "Mensaje enviado con exito"
    this.snackbar.color = "primary"
    this.snackbar.enabled = true
  }, () => {
    this.snackbar.text = "Error al enviar el correo"
          this.snackbar.color = "danger"
          this.snackbar.enabled = true
        });
        /*db.collection("contactData").add({
          name: this.name,
          email: this.email,
          message: this.textArea
        }).then(() => {
          this.snackbar.text = "Mensagem enviada com sucesso"
          this.snackbar.color = "success"
          this.snackbar.enabled = true
        }).catch(() => {
          this.snackbar.text = "Erro ao enviar mensagem"
          this.snackbar.color = "danger"
          this.snackbar.enabled = true
        })*/
      }
    }
  };
  </script>
  